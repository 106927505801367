import { css } from 'styled-components';

export const LinkStyle = css`
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.body}, sans-serif;


  ${({ theme }) => ({
    color: theme.colors.secondary,
  })};
`;
