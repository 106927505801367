const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  focus: '#5b9dd969',
  disabled: '#EDEDED',
  inputColor: '#626363',
  placeholder: '#8c8d8d',

  // main colors
  primary: '#073142',
  secondary: '#575858',
  tertiary: '#89949B',
  quarternary: '#2DBAB9',
  quinary: '#496672',
  senary: '#96A7AD',
  septenary: '#E9F0F5',

  // complementary colors
  success: '#5BA13C',
  danger: '#D20020',
  info: '#6A6B6B',
  successLight: '#7DBD7B',
  dangerLight: '#EE696D',
  infoLight: '#DDDDDD',

  // additional colors
  grayLight: '#FAFBFB',
  grayLightest: '#F7F7F7',
  grayOther: '#EDEDED',
  warning: '#FFC107',
  blueLight: '#74c4e2',
  primaryDark: '#041D28',
  secondaryDark: '#333333',
  successDark: '#315820',
  dangerDark: '#64000F',
  blueNavy: '#073142',

  basic: {
    blue: '#0099CE',
  },
};

export default colors;
