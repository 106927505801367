import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LabelStyle } from './index.styles';

const Label = styled.label`
  ${LabelStyle}
`;

const InputLabel = ({
  labelInput = 'label',
  labelId,
  label,
  labelColor = 'primary',
  required,
}) => (
  <Label id={labelId} as={labelInput} color={labelColor} required={required}>
    {label}
  </Label>
);

InputLabel.propTypes = {
  labelInput: PropTypes.string,
  labelId: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  required: PropTypes.bool,
};

export default InputLabel;
