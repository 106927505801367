import styled from 'styled-components';

export const StyledStatusSpan = styled.span`
  margin-bottom: 4px;
  margin-left: 0.3125rem;
  width : 21px;
`;

export const StyledForm = styled.form`
  max-width : 500px;
  margin : 30px;
  padding : 30px;
  border-radius : 4px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

export const StyledFormActions = styled.div`
  margin-top : 0.625rem;

  & > button {
    margin-right : 0.625rem;
}
`;

export const StyledStatusBar = styled.div`
  display : flex;
  max-width : 350px;
  align-items : flex-end;
`;

export const StyledError = styled.div`
  color : ${({ theme }) => theme.colors.danger};
  font-size : 14px;
  margin-top : 0.3125rem;
  font-family: Lato, sans-serif;
  min-height : 14px;
`;
