import styled, { createGlobalStyle } from 'styled-components';
import React from 'react';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import './appContainer.scss';
import reset from 'styled-reset';
import AppHeader from './header/AppHeader';
import AppContent from './AppContent';
import AppFooter from './AppFooter';
import { AppContainerStyle } from './AppContainer.styles';

if (process.env.NODE_ENV !== 'production') {
  whyDidYouRender(React);
}

const StyledAppContainer = styled.div`${AppContainerStyle}`;
StyledAppContainer.displayName = 'StyledAppContainer';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body {
    font-family: 'Open Sans Condensed', sans-serif;
  }
`;

export const AppContainerComponent = () => (
  <>
    <GlobalStyle />
    <StyledAppContainer>
      <AppHeader />
      <AppContent />
      <AppFooter />
    </StyledAppContainer>
  </>
);

export default AppContainerComponent;
