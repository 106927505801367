import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@ipc/ipc-core';

export const isURLExternal = (url) => /^https?:\/\//.test(url);

const LinkComponent = (props) => {
  const { to, children, ...rest } = props;

  return isURLExternal(to)
    ? (
      <a href={to} {...rest}>
        {children}
      </a>
    )
    : (
      <Link {...props} />
    );
};

LinkComponent.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default LinkComponent;
