import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLanguage } from '@ipc/ipc-core';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import { BreadcrumbData as Breadcrumbs } from '../../../Breadcrumb';
import { getBreadcrumbs } from './breadcrumbRoutes';

const HeaderContainer = styled.div`
  background-color : white;
  padding : 1.875rem;
`;

const PageMainHeader = ({ children }) => {
  const [{ currentLanguage }] = useLanguage();

  const BreadcrumbWithRouterData = useMemo(() => {
    const routes = getBreadcrumbs(currentLanguage);
    return withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumbs);
  }, [currentLanguage]);

  return (
    <HeaderContainer>
      <div>
        <BreadcrumbWithRouterData />
        {children}
      </div>
    </HeaderContainer>
  );
};

PageMainHeader.propTypes = {
  children: PropTypes.node,
};

export default PageMainHeader;
