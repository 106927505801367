import React from 'react';
import styled from 'styled-components';
import { tilesConfig } from './homeTilesConfig';
import { PageStyle, TitleStyle, TilesContainerStyle } from './Home.styles';
import Tile from '../../Tile';

const PageContainer = styled.div`
  ${PageStyle}
`;
const Title = styled.h1`
  ${TitleStyle}
`;
const TilesContainer = styled.div`
  ${TilesContainerStyle}
`;
const CenteredDiv = styled.div`
  flex-grow : 1;
  max-width :${({ theme }) => theme.breakpoints[2]};
  margin-top : 100px;
`;

export default () => (
  <PageContainer>
    <CenteredDiv>
      <Title>Applications</Title>
      <TilesContainer>
        {tilesConfig.map((tile) => <Tile key={tile.title} {...tile} />)}
      </TilesContainer>
    </CenteredDiv>
  </PageContainer>
);
