import typography from './typography';
import colors from './colors';

const breakpoints = ['768px', '1024px', '1360px', '1440px'];

const theme = {
  ...typography,
  colors,
  breakpoints,
};

export default theme;
