import { LightBlue } from '../../elements/Colors';

export const AppContentStyle = `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: scroll;
  border-radius: 8px;
  background-color: ${LightBlue}; 
`;
