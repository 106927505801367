import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link, { isURLExternal } from '../Link/Link';
import { TilerStyle, TilerTitleStyle, LinkStyle } from './Tile.styles';

const StyledTiler = styled.div`
  ${TilerStyle}
`;

const TilerTitle = styled.h3`
  ${TilerTitleStyle}
`;

const StyledLink = styled(Link)`
  ${LinkStyle}
`;

const Tile = ({
  title, imageUrl, size = 'md', backgroundColor, url,
}) => (
  <StyledLink size={size} to={url} target={isURLExternal(url) ? '_blank' : '_self'}>
    <StyledTiler backgroundColor={backgroundColor}>
      <img src={imageUrl} alt="service" />
      <TilerTitle>{title}</TilerTitle>
    </StyledTiler>
  </StyledLink>
);

Tile.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  url: PropTypes.string,
};

export default Tile;
