import { css } from 'styled-components';

export const LabelStyle = css`
  font-family: Lato, sans-serif;  
  color : ${({ theme, color }) => theme.colors[color] || color};
  margin-bottom : 0.625rem;

  &:after {
    content:  ${({ required }) => (required ? "'*'" : '')};
    color : ${({ theme }) => theme.colors.danger};
    margin-left : 0.3125rem;
  }
`;
