import React from 'react';
import { useLanguage } from '@ipc/ipc-core';
import { IntlProvider } from 'react-intl';
import * as PropTypes from 'prop-types';
import { messages } from '../../locales/locales';

export const AppIntlProvider = ({ children }) => {
  const [{ currentLanguage }] = useLanguage();
  return <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>{children}</IntlProvider>;
};

AppIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
