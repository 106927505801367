export const defaultFormValues = {
  s10Validation: '',
};

export const VALIDATION_STATUS = {
  PASSED: 1,
  FAILED: 0,
};

export const getValidationStatus = (response) => (response.error ? VALIDATION_STATUS.FAILED : VALIDATION_STATUS.PASSED);
