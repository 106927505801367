import React from 'react';
import ReactDOM from 'react-dom';
import './sass/index.scss';
import Root from './components/Root';
import 'normalize.css';
import './FontAwesomeIcons';

export default ReactDOM.render(
  <Root />,
  document.getElementById('root') || document.createElement('div'),
);
