import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ErrorBox = styled.div`
  color : ${({ theme }) => theme.colors.danger};
  font-size : 14px;
  margin-top : 0.3125rem;
  font-family: Lato, sans-serif;
`;

const Error = ({ message, showMessage = true, ...rest }) => (
  message
  && showMessage && (
    <ErrorBox {...rest}>
      {message}
    </ErrorBox>
  )
);

Error.propTypes = {
  message: PropTypes.string,
  showMessage: PropTypes.bool,
};

export default Error;
