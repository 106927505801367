import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import ErrorForm from './ErrorForm';
import Label from '../InputLabel';

const FormContainer = styled.div`
  display : flex;
  flex-direction : column;
  ${({ fullWidth }) => fullWidth && { width: '100%' }}
`;

const FormField = ({
  rootInput = 'div',
  labelInput = 'label',
  labelId,
  label,
  labelColor = 'primary',
  children,
  fieldName,
  required = false,
  errorMessageProps = { message: '' },
  ...rest
}) => {
  const intl = useIntl();

  return (
    <FormContainer as={rootInput} {...rest}>
      <Label
        labelInput={labelInput}
        labelId={labelId}
        label={label}
        labelColor={labelColor}
        required={required}
      />

      {children}

      {fieldName ? (
        <ErrorMessage name={fieldName} render={({ message }) => <ErrorForm {...errorMessageProps} message={intl.formatMessage({ id: message })} />} />
      ) : (
        errorMessageProps.showMessage && <ErrorForm {...errorMessageProps} />
      )}
    </FormContainer>
  );
};

FormField.propTypes = {
  rootInput: PropTypes.string,
  labelInput: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelId: PropTypes.string,
  labelColor: PropTypes.string,
  children: PropTypes.node,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  errorMessageProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
};

export default FormField;
