import React from 'react';
import ipcLogoSmall from '../../../images/ipc_logo_small.png';

export const AppHeaderRightContainer = () => (
  <div className="header-right-container">
    <img
      className="logo"
      alt="IPC logo"
      src={ipcLogoSmall}
    />
  </div>
);

export default AppHeaderRightContainer;
