import dashboardLogo from '../../../../images/dashboard-reporting.png';
import s10Logo from '../../../../images/s10-validation.png';
import ediTransactionLogo from '../../../../images/EDI-transaction-report.png';
import downloadLogo from '../../../../images/download-centre.png';
import ediFileLogo from '../../../../images/EDI-file-check.png';
import AtatLogo from '../../../../images/advanced-tracking-analysis-tool.png';
import documentLogo from '../../../../images/epg-document-centre.png';

export const tilesConfig = [
  {
    title: 'Dashboard Reporting',
    backgroundColor: 'burlywood',
    imageUrl: dashboardLogo,
    url: process.env.dashboardURL,
    size: 'md',
  },
  {
    title: 'S10 Validation',
    backgroundColor: 'darkred',
    imageUrl: s10Logo,
    url: '/s10',
    size: 'md',
  },
  {
    title: 'EDI Transaction Report',
    backgroundColor: '#1c1c39',
    imageUrl: ediTransactionLogo,
    url: process.env.ediReportdURL,
    size: 'md',
  },
  {
    title: 'Download Centre',
    backgroundColor: 'indianred',
    imageUrl: downloadLogo,
    url: process.env.downloadURL,
    size: 'md',
  },
  {
    title: 'EPG Lounge',
    backgroundColor: '#eab38a',
    imageUrl: documentLogo,
    url: process.env.documentURL,
    size: 'md',
  },
  {
    title: 'EDI File Check',
    backgroundColor: '#508150',
    imageUrl: ediFileLogo,
    url: process.env.ediFileURL,
    size: 'md',
  },
  {
    title: 'Advanced Tracking & Analysis Tool',
    backgroundColor: 'darkblue',
    imageUrl: AtatLogo,
    url: process.env.AtatURL,
    size: 'md',
  },
];
