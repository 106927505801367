import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@ipc/ipc-components';
import { withRouter, useLanguage } from '@ipc/ipc-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { TitleStyle, SubtitleStyle } from './Header.styles';

const StyledTitle = styled.h3`
  ${TitleStyle}
`;
const SubtitleContainer = styled.div`
  ${SubtitleStyle}
`;

const SubtitleHeader = ({ title, route, history }) => {
  const [, language] = useLanguage();

  const handleClick = () => {
    history.push(language(route));
  };

  return (
    <>
      <SubtitleContainer>
        <Button style={{ backgroundColor: 'transparent' }} onClick={handleClick}>
          <FontAwesomeIcon onClick={handleClick} icon={faChevronLeft} />
        </Button>
        <StyledTitle>{title}</StyledTitle>
      </SubtitleContainer>
    </>
  );
};

SubtitleHeader.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any),
};

export default memo(withRouter(SubtitleHeader));
