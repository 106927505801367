import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faIgloo, faChevronDown, faUser, faUserTimes,
  faSignInAlt, faSignOutAlt, faTimes, faInfo, faBug, faExclamationTriangle, faCheck,
} from '@fortawesome/free-solid-svg-icons';

// Add more icons here, use <FontAwesomeIcon icon="igloo" /> to use the icon in components
library.add(faIgloo);
library.add(faChevronDown);
library.add(faUser);
library.add(faUserTimes);
library.add(faSignInAlt);
library.add(faSignOutAlt);
library.add(faTimes);
library.add(faInfo);
library.add(faBug);
library.add(faExclamationTriangle);
library.add(faCheck);
