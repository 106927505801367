const typography = {
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',

    paragraph: '1.5rem',
    bodySmall: '0.75rem',
    title: '1.375rem',
    h1: '2.5rem',
  },
  fonts: {
    body: 'Lato',
    header: 'Open Sans Condensed',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
};

export default typography;
