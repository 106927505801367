import { css } from 'styled-components';

const primaryStyle = css`
${({ primary, theme }) => (primary ? {
    backgroundColor: theme.colors.basic.blue,
    borderColor: theme.colors.basic.blue,
    color: 'white',
  } : '')};
`;

const disabledStyle = css`
${({ disabled }) => (disabled ? {
    opacity: 0.5,
    cursor: 'default',
  } : '')};  
`;

export const ButtonStyle = css`
  text-transform: uppercase;
  display: inline-block;
  ${primaryStyle}
  ${disabledStyle}

   &:focus {
    outline:0;
  }
`;
