export default {
  lang: 'en',
  messages: {
    'app.title': 'STORM',
    'app.copyright': '© {copyrightYear} International Post Corporation',
    'app.signIn': 'sign in',
    'app.signOut': 'sign out',
    'message.notFound': '404: Page not found',
    'button.login': 'Login',
    'form.button.check': 'Check',
    'form.button.clear': 'Clear',
    'breadcrumb.s10validation': 'S10 validation',
    's10.label': 'S10',
    's10.requiredField': 'Mandatory field, please enter a value',
  },
};
