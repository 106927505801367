/* eslint-disable global-require */
import React from 'react';
import { NotificationProvider } from '@ipc/ipc-components';
import {
  Router, Route, LanguageProvider, LanguageRouting,
} from '@ipc/ipc-core';
import { ThemeProvider } from 'styled-components';
import {
  QueryCache,
  ReactQueryCacheProvider,
} from 'react-query';
import { AppIntlProvider } from './common/AppIntlProvider';
import { defaultLanguage, supportedLanguages } from '../locales/locales';
import AppContainer from './application/AppContainer';
import theme from './common/theme';

if (process.env.NODE_ENV === 'development' && process.env.mock === 'true') {
  require('../mocks');
}
const AppLanguageRouting = (props) => <LanguageRouting {...props} AppContainer={AppContainer} />;
const queryCache = new QueryCache();
const Root = () => (
  <ReactQueryCacheProvider queryCache={queryCache}>
    <ThemeProvider theme={theme}>
      <LanguageProvider supportedLanguages={supportedLanguages} defaultLanguage={defaultLanguage}>
        <NotificationProvider>
          <Router basename={process.env.basePath}>
            <AppIntlProvider>
              <Route component={AppLanguageRouting} />
            </AppIntlProvider>
          </Router>
        </NotificationProvider>
      </LanguageProvider>
    </ThemeProvider>
  </ReactQueryCacheProvider>
);
export default Root;
