import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Button from '../../Button/Button';
import { Input } from '../../Input/Input';

import { PageMainHeader, PageSubtitleHeader } from '../Main';
import { getValidation } from './api';
import {
  StyledError, StyledForm, StyledFormActions, StyledStatusBar, StyledStatusSpan,
} from './S10Validation.styles';
import { defaultFormValues, getValidationStatus, VALIDATION_STATUS } from './utils';

const { PASSED, FAILED } = VALIDATION_STATUS;

const renderStatusIcon = (validationStatus, isLoading) => {
  if (isLoading) {
    return <CircularProgress data-testid="s10Loading" size="100%" />;
  }

  switch (validationStatus) {
  case PASSED:
    return <FontAwesomeIcon data-testid="s10PassedVal" size="lg" color="green" icon={faCheckCircle} />;
  case FAILED:
    return <FontAwesomeIcon data-testid="s10FailedVal" size="lg" color="red" icon={faTimesCircle} />;
  default:
    return null;
  }
};

export default () => {
  const intl = useIntl();
  const methods = useForm({
    defaultValues: defaultFormValues,
    mode: 'onSubmit',
  });
  const [valueForValidation, setValue] = useState('');
  const { data, isLoading, isSuccess } = useQuery(['validation', valueForValidation], getValidation, {
    enabled: valueForValidation,
  });

  const validationStatus = isSuccess ? getValidationStatus(data) : undefined;

  const cleanValidationStatus = () => {
    if (valueForValidation) {
      setValue('');
    }
  };

  const onSubmit = ({ s10Validation }) => {
    setValue(s10Validation);
  };

  const onClear = () => {
    methods.reset();
    cleanValidationStatus();
  };

  return (
    <>
      <PageMainHeader>
        <PageSubtitleHeader route="/home" title={intl.formatMessage({ id: 'breadcrumb.s10validation' })} />
      </PageMainHeader>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
          <StyledStatusBar>
            <Input
              formFieldProps={{
                fieldName: 's10Validation',
                label: intl.formatMessage({ id: 's10.label' }),
                labelId: 's10Validation',
                required: true,
                fullWidth: true,
              }}
              onChange={cleanValidationStatus}
            />
            <StyledStatusSpan>
              {renderStatusIcon(validationStatus, isLoading)}
            </StyledStatusSpan>
          </StyledStatusBar>

          <StyledError>
            {validationStatus === FAILED && data?.error?.message}
          </StyledError>

          <StyledFormActions>
            <Button primary disabled={isLoading} type="submit">{intl.formatMessage({ id: 'form.button.check' })}</Button>
            <Button disabled={isLoading} onClick={onClear}>{intl.formatMessage({ id: 'form.button.clear' })}</Button>
          </StyledFormActions>
        </StyledForm>
      </FormProvider>
    </>
  );
};
