import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@ipc/ipc-core';

import { LinkStyle } from './BreadcrumbLink.styles';

const LinkStyled = styled(Link)`
  ${LinkStyle};
  color: ${({ theme, color }) => theme.colors[color] || color};
`;

const BreadcrumbLink = ({ active, ...props }) => {
  const activeProps = active && {
    'aria-current': 'page',
    color: 'blueNavy',
    onClick: (event) => event.preventDefault(),
  };

  return <LinkStyled {...activeProps} {...props} />;
};

BreadcrumbLink.propTypes = {
  active: propTypes.bool,
};

export default BreadcrumbLink;
