import { css } from 'styled-components';

const sizes = {
  md: { width: '155px', height: '140px' },
};

export const TilerStyle = css`
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
  padding : 10px;
  height : 100%;
  background-color : ${({ theme, backgroundColor }) => theme.colors[backgroundColor] || backgroundColor};
`;

export const TilerTitleStyle = css`
  color : white;
  font-family: ${({ theme }) => theme.fonts.body};
  text-align : center;
  margin-top : 10px;
`;

export const LinkStyle = css`
  flex : 0 0  ${({ size }) => sizes[size].width};
  height : ${({ size }) => sizes[size].height};

  text-decoration: none;
`;
