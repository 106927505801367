import { css } from 'styled-components';

export const PageStyle = `
  display : flex;
  justify-content : center;
  height : 100%;
`;

export const TitleStyle = css`
  text-align : center;
  margin : 0;
  ${({ theme }) => ({
    color: theme.colors.blueNavy,
    fontFamily: theme.fonts.header,
    fontWeight: theme.fontWeights.semibold,
    fontSize: theme.fontSizes.h1,
  })}
`;

export const TilesContainerStyle = `
  display : flex;
  justify-content : center;
  flex-direction : row;
  flex-wrap: wrap;
  margin-top : 40px;

  & > a {
    margin-right : 15px;
    margin-bottom : 15px;
  }
`;
