import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '@ipc/ipc-core';

import Breadcrumb from './Breadcrumb';
import BreadcrumbLink from './BreadcrumbLink';

const BreadcrumbData = ({ breadcrumbs }) => {
  const [, language] = useLanguage();

  return (
    <Breadcrumb aria-label="breadcrumb">
      <BreadcrumbLink color="blueNavy" to={language('/home')}>
        <FontAwesomeIcon size="lg" icon={faHome} />
      </BreadcrumbLink>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <BreadcrumbLink key={match.url} active={index === breadcrumbs.length - 1} to={match.url}>
          {breadcrumb}
        </BreadcrumbLink>
      ))}
    </Breadcrumb>
  );
};

BreadcrumbData.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
};

export default BreadcrumbData;
