import React from 'react';
import { injectIntl } from 'react-intl';
import './appHeader.scss';
import AppHeaderLeftContainer from './AppHeaderLeftContainer';
import AppHeaderCenterContainer from './AppHeaderCenterContainer';
import AppHeaderRightContainer from './AppHeaderRightContainer';

export class AppHeader extends React.Component {
  componentDidMount() {
    this.setTitle();
  }

  componentDidUpdate() {
    this.setTitle();
  }

  setTitle = () => {
    const { intl } = this.props;
    document.title = intl.formatMessage({ id: 'app.title' });
  };

  render = () => (
    <div id="header">
      <AppHeaderLeftContainer />
      <AppHeaderCenterContainer />
      <AppHeaderRightContainer />
    </div>
  );
}

export default injectIntl(AppHeader);
