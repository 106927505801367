import { css } from 'styled-components';

export const InputStyles = css`
  &.MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.infoLight};
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${({ theme }) => theme.colors.focus};
  }

  & .MuiOutlinedInput-input {
    font-family: Lato, sans-serif;
    height: 30px;

    ${({ theme }) => ({
    backgroundColor: theme.colors.white,
    color: theme.colors.inputColor,
  })};

    padding: 0 10px;
    box-sizing: border-box;
  }

  &.MuiOutlinedInput-root.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.disabled};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.infoLight};
    }

    .MuiInputBase-input {
      background-color: ${({ theme }) => theme.colors.disabled};
    }
  }
`;
