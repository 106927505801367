import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const BreadcrumbSimpleTitle = ({ idMessage }) => {
  const intl = useIntl();

  return <span>{intl.formatMessage({ id: idMessage })}</span>;
};

BreadcrumbSimpleTitle.propTypes = {
  idMessage: PropTypes.string,
};

export const getBreadcrumbs = (language) => [{
  path: `/${language}/s10`,
  breadcrumb: (props) => <BreadcrumbSimpleTitle idMessage="breadcrumb.s10validation" {...props} />,
}];
