import { css } from 'styled-components';

export const TitleStyle = css`
  text-transform: uppercase;

  ${({ theme }) => ({
    fontFamily: theme.fonts.header,
    fontSize: theme.fontSizes.title,
    fontWeight: theme.fontWeights.bold,
    fontStretch: 'condensed',
    lineHeight: '1.56',
    color: theme.colors.blackNavy,
  })}
`;

export const SubtitleStyle = css`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-top : 1.875rem;
  margin-left : 1.25rem;
  color: ${({ theme }) => theme.colors.blackNavy};
`;
