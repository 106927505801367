import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const AppFooter = ({ year = new Date().getFullYear() }) => (
  <div className="appFooter">
    <span className="copyright">
      <FormattedMessage
        id="app.copyright"
        values={{ copyrightYear: year }}
      />
    </span>
  </div>
);

AppFooter.propTypes = {
  year: PropTypes.number,
};

export default AppFooter;
