import {
  Redirect, Route, Switch, useLanguage, withRouter,
} from '@ipc/ipc-core';
import React from 'react';
import styled from 'styled-components';
import NotFound from '../common/NotFound';
import { AppContentStyle } from './AppContent.styles';
import Home from '../common/pages/Home/Home';
import S10Validation from '../common/pages/S10/S10Validation';

const StyledAppContent = styled.div`${AppContentStyle}`;

export const AppContent = () => {
  const [, language] = useLanguage();
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={`/${language('home')}`} />} />
      <Route exact path={language('/')} render={() => <Redirect to={`/${language('home')}`} />} />
      <Route>
        <StyledAppContent>
          <Switch>
            <Route exact path={language('/home')} component={Home} />
            <Route exact path={language('/s10')} component={S10Validation} />
            <Route component={NotFound} />
          </Switch>
        </StyledAppContent>
      </Route>
    </Switch>
  );
};

export default withRouter(AppContent);
