import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { FormControl } from '../FormControl';
import { InputStyles } from './Input.styles';

export const StyledInput = styled(OutlinedInput)`
  ${InputStyles}
`;

export const Input = ({
  formFieldProps, inputProps, className, ...rest
}) => {
  const { register } = useFormContext();

  return (
    <FormControl {...formFieldProps}>
      <StyledInput
        name={formFieldProps.fieldName}
        inputRef={register}
        className={className}
        inputProps={{
          'aria-labelledby': formFieldProps.labelId,
          ...inputProps,
        }}
        {...rest}
      />
    </FormControl>
  );
};

Input.propTypes = {
  formFieldProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputProps: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};
