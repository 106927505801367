import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from '@ipc/ipc-core';
import { FormattedMessage } from 'react-intl';

const AppHeaderLeftContainer = ({ history }) => {
  const handleTitleClick = () => {
    history.push('/home');
  };

  return (
    <div className="header-left-container">
      <div className="app-title" onClick={handleTitleClick}>
        <FormattedMessage id="app.title" tagName="h1" />
      </div>
    </div>
  );
};

AppHeaderLeftContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
};

export default withRouter(AppHeaderLeftContainer);
