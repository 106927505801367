import axios from 'axios';

const s10ValidationUrl = (s10label) => `${process.env.baseAPI}/api/s10/${s10label}/validate`;

export const getValidation = async (_, s10Label) => {
  try {
    const response = await axios.get(s10ValidationUrl(s10Label));
    return response.data;
  } catch (error) {
    return Promise.reject(error.response || error.message);
  }
};
